<template>
  <div>Guardando, no cierres la ventana porfavor</div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "Back",
  methods: {
    ...mapActions("payments", ["connect"]),
    stripeConect() {
      this.connect({
        type: "stripe",
        account_id: this.$route.params.account_id,
      }).then(() => {
        window.close();
      });
    },
  },
  mounted() {
    this.stripeConect();
  },
};
</script>